import design1 from "./wish someone/birthday/birthday";
import design2 from "./wish someone/birthday/birthday2";
import design3 from "./wish someone/birthday/birthday3";
import design3_1 from "./wish someone/birthday/birthday3_1";
import design4 from "./wish someone/birthday/birthday4";
import design5 from "./wish someone/birthday/birthday5";
import design6 from "./wish someone/birthday/birthday6";
import design7 from "./wish someone/birthday/birthday7";
import design8 from "./wish someone/birthday/birthday8";
import design9 from "./wish someone/birthday/birthday9";
import design10 from "./wish someone/birthday/birthday10";
import design11 from "./wish someone/birthday/birthday11";
import design12 from "./wish someone/birthday/birthday12";
import design13 from "./wish someone/birthday/birthday13";
import design14 from "./wish someone/birthday/birthday14";
import design15 from "./wish someone/birthday/birthday15";
import design16 from "./wish someone/birthday/birthday16";
import design17 from "./wish someone/birthday/birthday17";
import design18 from "./wish someone/birthday/birthday18";
import design19 from "./wish someone/birthday/birthday19";
import design20 from "./wish someone/birthday/birthday20";
import design21 from "./wish someone/birthday/birthday21";
import design22 from "./wish someone/birthday/birthday22";
import design23 from "./wish someone/birthday/birthday23";
import design24 from "./wish someone/birthday/birthday24";
import design25 from "./wish someone/birthday/birthday25";
import design26 from "./wish someone/birthday/birthday26";
import design27 from "./wish someone/birthday/birthday27";
import design28 from "./wish someone/birthday/birthday28";
import design29 from "./wish someone/birthday/birthday29";
import design30 from "./wish someone/birthday/birthday30";

// const template = {
//     invitation: {

//     },
//     wish_someone: {
//         birthday : [[design1,"design1"],[design2,"design2"],[design3,"design3"]],
//         work_aniversary: [[design1,"design1"],[design2,"design2"],[design3,"design3"]],
//         festivals: [[design4,"design4"],[design5,"design5"],[design6,"design6"]],
//         events: [[design4,"design4"],[design5,"design5"],[design6,"design6"]] 
//     }
// }

export const allTemplates = {
    design22: design22,
    design21: design21,
    design23: design23,
    design24: design24,
    design25: design25,
    design26: design26,
    design27: design27,
    design1: design1,
    design2: design2,
    design3: design3,
    design4: design4,
    design4: design4,
    design5: design5,
    design6: design6,
    design7: design7,
    design8: design8,
    design9: design9, 
    design11: design11,
    design12: design12,
    design13: design13,
    design14: design14,
    design15: design15,
    design16: design16,
    design17: design17,
    design18: design18,
    design19: design19,
    design20: design20,
    design21: design21,
    design22: design22,
    design23: design23,
    design24: design24,
    design25: design25,
    design26: design26,
    design27: design27,
    design28: design28,
    design29: design29,
    design30: design30
}

const template = {
    invitation: {

    },
    wish_someone: {
        birthday : {
            design30: design30,
            design29: design29,
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,

            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
            design19: design19,
            design20: design20,
          
        },
        work_aniversary: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28, 
            
            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
        },
        festivals: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,

            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
            design20: design20
        },
        events: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,

            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
        },
        employee_of_the_month: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,

            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
        },
        welcome_to_the_team: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,

            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
        },
        farewell: {
            design22: design22,
            design21: design21,
            design23: design23,
            design24: design24,
            design25: design25,
            design26: design26,
            design27: design27,
            design28: design28,
            
            design1: design1,
            design2: design2,
            design3: design3,
            design4: design4,
            design14: design14,
            design5: design5,
            design17: design17,
            design15: design15,
            design16: design16,
            design18: design18,
            design6: design6,
            design7: design7,
            design8: design8,
            design9: design9, 
            design11: design11,
            design12: design12,
            design13: design13,
            design19: design19,
            design20: design20
        } 
    }
}

export default template;
