import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Imaginationheader from "../imaginationHeader";
import axios from "axios";
import razorpay from "../Images/razorpay_logo.png";
import enteramount from "../Images/enter-amount.png";
import coin from "../Images/coin.png";
import calendar from "../Images/calendar.png";
import status from "../Images/status.png";

export default function AiPaymentHistory(sendData) {
  const navigate = useNavigate();
  const NavigateTools = () => {
    navigate("/tools");
  };

  const [aiPayment, setAipayment] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const guitarUser = JSON.parse(localStorage.getItem("guitar_user"));
  const userId = guitarUser ? guitarUser.uid : null;

  const fetchAiPaymentHistory = async () => {
    if (!userId) return;
    try {
      const res = await axios.get(
        // `https://varnz-backend.webgarh.net/api/tool-payment/${userId}`
        `${process.env.REACT_APP_IMAGINATION_PAYMENT_CHECKOUT}/${userId}`
      );
    
      const filteredData = res.data
      .filter((payment) => payment.status === "Success")
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setAipayment(filteredData);
    } catch (error) {
      console.error("Error fetching AI payment history:", error);
    }
  };

  useEffect(() => {
    fetchAiPaymentHistory();
  }, [userId]);

  // Logic for pagination
  const indexOfLastPayment = currentPage * itemsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - itemsPerPage;
  const currentPayments = aiPayment.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(aiPayment.length / itemsPerPage);

  // Generate pagination array
  const generatePagination = () => {
    const pagination = [];

    // Show all pages if totalPages is less than or equal to 5
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(i);
      }
    } else {
      // Always add the first two pages
      pagination.push(1, 2);

      // Include the current page if it’s between the first two and the last two
      if (currentPage > 2 && currentPage < totalPages - 1) {
        pagination.push(currentPage);
      }

      // Add last two pages
      if (totalPages > 2) {
        pagination.push(totalPages - 1, totalPages);
      }
    }

    return [...new Set(pagination)]; // Remove duplicates
  };



  // Handle previous and next pagination
  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

      // function for checking limit of the generate image start
      const USER_DETAILS = localStorage.getItem("guitar_user");
      const parsedUserDetails = USER_DETAILS ? JSON.parse(USER_DETAILS) : null;
      useEffect(() => {
        const currentUserId = parsedUserDetails?.uid;
    
        if (window.location.pathname === "/ai-payment-history") {
          const storedUserId = sessionStorage.getItem("currentUserId");
    
          // If the user has changed or page hasn't been refreshed, reload
          if (storedUserId !== currentUserId) {
            sessionStorage.setItem("currentUserId", currentUserId);
            window.location.reload();
          }
        }
      }, []);

  return (
    <>
      <Imaginationheader />
      <div className="imagination-history-container card-history-page">
        <div className="cstm-container imagination-container">
          <div className="generated-images-grid">
            <div className="step2-main-heading">
              <h2>Payment History</h2>
              <p className="generate-btn brdr-btn" onClick={NavigateTools}>
                <i className="fas fa-chevron-left"></i> Back to tools
              </p>
            </div>

            <div className="payment-method">
              <h2>Payment Method</h2>
              <div className="payment-method-inner">
                <img src={razorpay} alt="razorpay" />
                <div className="payment-method-right">
                  {aiPayment.length > 0 && aiPayment[0].uid.length > 0 ? (
                    <>
                      <h4>
                          {aiPayment[0].uid[0].name.charAt(0).toUpperCase() +
                          aiPayment[0].uid[0].name.slice(1).toLowerCase()}
                      </h4>
                      <p>{aiPayment[0].uid[0].email}</p>
                    </>
                  ) : (
                    <p>{guitarUser ? guitarUser.email : ""}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="payment-history">
              <table>
                <thead>
                  <tr>
                    <th>
                      <img src={enteramount} alt="amount" />
                      Amount
                    </th>
                    <th>
                      <img src={coin} alt="coin" />
                      Credits
                    </th>
                    <th>
                      <img src={status} alt="status" />
                      Status
                    </th>
                    <th>
                      <img src={calendar} alt="calendar" />
                      Date/time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayments.length > 0 ? (
                    currentPayments.map((payment, index) => (
                      <tr key={index}>
                        <td>${payment.amount}</td>
                        <td>{payment.credit}</td>
                        <td>{payment.status}</td>
                        <td>{new Date(payment.created_at).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No payment history available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* pagination */}
            {totalPages > 1 && (
              <div className="pagination">
                <button onClick={handlePrevious} disabled={currentPage === 1}>
                  &lt;
                </button>

                {generatePagination().map((page, index) => (
                  <button
                    key={index}
                    onClick={() => typeof page === "number" && paginate(page)}
                    className={page === currentPage ? "active" : ""}
                    disabled={typeof page === "string"} // Disable button for ellipsis
                  >
                    {page}
                  </button>
                ))}

                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}