import axios from "axios";
import html2canvas from 'html2canvas';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import edit from "../../image/editt.png";
import download from "../../image/download_icon.png";
import share from "../../image/share.png";
import { useEffect, useState } from "react";
import domtoimage from 'dom-to-image';
import { Modal } from "react-bootstrap";



export const shareImageCreate = async (e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard) => {
    try {
        setSharePlease({
            text: "Please wait..",
            disabled: true
        });

        if (setDownloadCard) {
            setDownloadCard({
                download: false,
                share: true
            })
        }
        const elementToCapture = captureDivRef1.current;
        const canvas = await html2canvas(elementToCapture, { scale: 3, useCORS: true });
        const imgDataUrl = canvas.toDataURL('image/png');
        const blob = await fetch(imgDataUrl).then((res) => res.blob());
        const formData = new FormData();
        formData.append('title', showMessage.heading);
        formData.append('message', showMessage.message);
        formData.append('type', showTabs.type);
        formData.append('category', showTabs.category);
        formData.append('occasion', showTabs.occasion);
        formData.append('tabs', clickedTabs.tabs);
        formData.append('image', blob, 'image.png');
        const url = process.env.REACT_APP_SHARE_IMAGE_URL;
        const response = await axios.post(url, formData);
        setSharePlease({
            text: "Share",
            disabled: false
        });
        if (setDownloadCard) {
            setDownloadCard({
                download: false,
                share: false
            })
        }
        // Navigate(`/share?image=${response.data.data._id}`);
        const newTab = window.open(`/share?image=${response.data.data._id}`, '_blank');
        if (newTab) {
            newTab.focus(); // Focus on the new tab if it was successfully opened
        }
        return;
    } catch (err) {
        console.log(err);
    }
}

export const shareImageCreate1 = async (e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs) => {
    try {
        setSharePlease({
            text: "Please wait..",
            disabled: true
        });
        const elementToCapture = captureDivRef1.current;
        // const originalHeight = elementToCapture.offsetHeight;
        // const originalWidth = elementToCapture.offsetWidth;
        // elementToCapture.style.height = `${originalHeight}px`;
        // elementToCapture.style.width = `${originalWidth}px`;
        const canvas = await html2canvas(elementToCapture, { scale: 3 });
        const imgDataUrl = canvas.toDataURL('image/png');
        const blob = await fetch(imgDataUrl).then((res) => res.blob());
        const formData = new FormData();
        formData.append('title', showMessage.heading);
        formData.append('message', showMessage.message);
        formData.append('type', showTabs.type);
        formData.append('category', showTabs.category);
        formData.append('occasion', showTabs.occasion);
        formData.append('tabs', clickedTabs.tabs);
        formData.append('is_saved', 1);
        formData.append('image', blob, 'image.png');
        const url = process.env.REACT_APP_SHARE_IMAGE_URL;
        const response = await axios.post(url, formData);
        setSharePlease({
            text: "Share",
            disabled: false
        });
        // Navigate(`/share?image=${response.data.data._id}`);
        const newTab = window.open(`/share?image=${response.data.data._id}`, '_blank');
        if (newTab) {
            newTab.focus(); // Focus on the new tab if it was successfully opened
        }
        return;
    } catch (err) {
        console.log(err);
    }
}

// export const handleDownloadClicks = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom) => {
//     if (downloadText.disabled) {
//         return;
//     }

//     setDownloadText({
//         text: "Please wait..",
//         disabled: true
//     })

//     setTimeout(() => {
//         let elementToCapture = "";
//         elementToCapture = captureDivRef1.current;
//         const originalHeight = elementToCapture.offsetHeight;
//         const originalWidth = elementToCapture.offsetWidth;
//         const node = captureDivRef1.current;

//         const devicePixelRatio = window.devicePixelRatio || 1; // Get the device's pixel ratio
//         const scale = devicePixelRatio; // Set the scale based on the device's pixel ratio


//         if (is_dom) {
//             domtoimage.toPng(node,{ quality:1.0, width: elementToCapture.clientWidth * scale,
//                 height: elementToCapture.clientHeight * scale,
//                 style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}})
//                 .then((dataUrl) => {
//                     const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
//                     let filename = "";
//                     if (title === "Others")
//                         filename = `varnz_message_${random4DigitNumber}.png`;
//                     else
//                         filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

//                     // Create a link element to trigger the download
//                     const a = document.createElement('a');
//                     a.href = dataUrl;
//                     a.download = filename;

//                     // Trigger the download
//                     document.body.appendChild(a);
//                     a.click();

//                     // Cleanup: remove the link element from the DOM
//                     document.body.removeChild(a);

//                     alert.success("Downloaded Successfully!", {
//                         timeout: 3000
//                     });

//                     // Reset the download button state if needed
//                     setDownloadText({
//                         text: "Download",
//                         disabled: false
//                     });

//                     // Perform any additional actions or data updates
//                     sendData("download-successfully");
//                 })
//                 .catch((error) => {
//                     console.error('Error capturing element:', error);
//                 });
//         }
//     }, 100)
// };


function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    try {
        const item = JSON.parse(itemStr);
        const now = new Date();

        // Compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item has expired, remove it from storage and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    } catch (e) {
        // If JSON parsing fails, remove the corrupted item and return null
        localStorage.removeItem(key);
        console.error("Failed to parse item from localStorage:", e);
        return null;
    }
}
export const handleDownloadClick = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard, downloadSize, setShowInstagramPost, setIsDownload, setIsSingupDownload) => {
    // console.log(setIsSingupDownload, 'setIsSingupDownload');



    // console.log('instgram working here=======');

    localStorage.removeItem("dataURl");
    localStorage.removeItem("filename");
    localStorage.removeItem("instagramClicked");

    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }

    if (downloadSize !== "1" && setDownloadText) {
        setDownloadText({
            text: "Please wait..",
            disabled: true,
        });
    }

    // Handle Instagram Post case
    if (downloadSize === "1") {
        setShowInstagramPost(true);
    }

    if (downloadSize === "1") {
        setShowInstagramPost(true)
        // el.classList.add("insta-post")
    }

    if (downloadSize === "twitter") {
        setShowInstagramPost(true)
        // el.classList.add("insta-post")
    }


    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }
    setTimeout(() => {
        const elementToCapture = captureDivRef1.current;
        // console.log(elementToCapture, ':: elementToCapture');

        let recall = false;

        // elementToCapture.classList.add('instagram-size-1080');

        if (downloadSize === "twitter") {
            console.log('twitter if');
            elementToCapture.classList.add('twitter-size-1600');  // New class for Twitter
        }
        elementToCapture.classList.add('actual-size');
        // console.log('Class added for Twitter1:', elementToCapture.classList); // Log the class list

        // Determine the original image source
        const originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage ||
            captureDivRef1.current.querySelector("img#image-div")?.src || '';

        // Handle image and proceed with canvas capture
        if (downloadedImage) {
            console.log('download image');

            const imgElement = elementToCapture.querySelector("img#image-div");
            const divImage = elementToCapture.querySelector("div#image-div");

            if (divImage) {
                divImage.style.backgroundImage = `url("${downloadedImage}")`;
                if (!recall) abcd();
            }

            if (imgElement) {
                imgElement.src = downloadedImage;
                imgElement.onload = () => {
                    if (!recall) abcd();
                };
            }
        } else {
            abcd();
        }

        function abcd() {
            let width, height, owidth, oheight;
            owidth = elementToCapture.offsetWidth;
            oheight = elementToCapture.offsetHeight;
            const paragraphElement = captureDivRef1.current.querySelector('#template-message');
            console.log(paragraphElement);
            let originalSize = "";
            if (downloadSize === '0') {
                width = elementToCapture.offsetWidth; // Use the current width of the element
                height = elementToCapture.offsetHeight; // Use the current height of the element
            }
            else if (downloadSize === '11') {
                width = 1080;
                height = 1080;
                if (paragraphElement) {
                    let originalSize = paragraphElement.style.fontSize;
                    if (originalSize === "") {
                        originalSize = "20px";
                    }
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                    // console.log(size2,"paragraphElement",paragraphElement,originalSize)

                }
            }
            else if (downloadSize === '2') {
                width = 1080;
                height = 566;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`;
                }
            }
            else if (downloadSize === '3') {
                width = 1080;
                height = 1350;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                }
            }
            // Handle Twitter Post case
            else if (downloadSize === 'twitter') {
                width = 1600;
                height = 900;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize || '10px';
                    const size2 = (parseInt(originalSize) * 1.7);
                    paragraphElement.style.fontSize = `${size2}px`;
                }
            }

            html2canvas(elementToCapture, {

                scale: downloadSize === '0' ? scale : 2,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent",
                onclone: (clonedDoc) => {
                    console.log('instagram size');
                    const clonedElement = clonedDoc.querySelector('.instagram-size-1080');

                    // Apply transformations for Instagram (existing functionality)
                    if (downloadSize === '1' && clonedElement) {
                        clonedElement.classList.add('insta-post');

                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 2}px`;
                        }
                        clonedElement.style.width = '1080px';
                        clonedElement.style.height = '1080px';
                    }

                    else if (downloadSize === 'twitter') {
                        clonedElement.style.width = '1600px';  // Add px for Twitter post width
                        clonedElement.style.height = '900px';  // Add px for Twitter post height
                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 1}px`;
                        }
                    }
                }
            }).then(canvas => {
                handleDownloadSuccess(canvas);
                restoreOriginalState();
                recall = true;
            });
        }

        function handleDownloadSuccess(canvas) {
            const sessionData = JSON.parse(sessionStorage.getItem(window.location.pathname === "/" ? "formData" : "ai_cardData"));
            const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
            const formattedOccasion = (sessionData?.occasion || "").replace(/_/g, '-');


            const filename = `Varnz-${formattedOccasion}-greeting-card-${random4DigitNumber}.png`;


            // Check if HD is stored in sessionStorage
            const isHD = sessionStorage.getItem("isHDActual") === "true";

            // Set quality based on HD mode
            const quality = isHD ? 1.0 : 0.1;

            // console.log(quality, 'quality');

            const dataURL = canvas.toDataURL('image/webp', quality);

            const a = document.createElement('a');
            a.href = dataURL;
            a.download = filename;

            localStorage.setItem("dataURl", dataURL);
            localStorage.setItem("filename", filename);
            const storedEmail = getItemWithExpiry('user_email');
            const LoginUserEmail = JSON.parse(localStorage.getItem("guitar_user"));



            axios.get(process.env.REACT_APP_VISITED_USERS)
                .then(response => {
                    const userMatch = response.data.data.find(user => user.email === storedEmail);

                    const instagramClicked = localStorage.getItem("instagramClicked");

                    if (LoginUserEmail) {
                        console.log(LoginUserEmail, 'LoginUserEmail if');
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);
                    } else if (userMatch) {
                        console.log('else if 1');
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);
                        // Clear the localStorage after download
                        localStorage.removeItem("dataURl");
                        localStorage.removeItem("filename");
                    } else if (window.location.pathname === '/tools') {
                        console.log('else if 2');
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);
                    } else if (window.location.pathname === '/multiform' || window.location.pathname === '/template-edit' || window.location.pathname === '/template-history') {
                        console.log('else if 3');
                        // console.log("multiform");
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);
                    } else {
                        console.log('else');
                        if (instagramClicked !== "true") {
                            console.log("No data available for download");
                            finalizeDownload(true);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user records:', error);
                    finalizeDownload(true);
                });
        }

        function restoreOriginalState() {
            if (downloadedImage) {
                const imgElement = captureDivRef1.current.querySelector("img#image-div") || captureDivRef1.current.querySelector("div#image-div");
                if (imgElement) {
                    if (imgElement.tagName === 'IMG') {
                        imgElement.src = originalImage;
                    } else {
                        imgElement.style.backgroundImage = originalImage;
                    }
                }
            }
            elementToCapture.classList.remove('instagram-size-1080');
            setShowInstagramPost(false);
            if (setDownloadCard) {
                setDownloadCard({ download: false, share: false });
            }
        }

        function finalizeDownload(setIsDownloadValue) {
            setDownloadText({ text: "Download", disabled: false });
            setIsDownload(setIsDownloadValue);
        }
    }, 100);
};



export const InstahandleDownloadClick = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard, downloadSize, setShowInstagramPost, setIsDownload, setIsSingupDownload) => {

    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }

    if (downloadSize !== "1" && setDownloadText) {
        setDownloadText({
            text: "Please wait..",
            disabled: true,
        });
    }

    // Handle Instagram Post case
    if (downloadSize === "1") {
        setShowInstagramPost(true);
    }

    if (downloadSize === "1") {
        setShowInstagramPost(true)
        // el.classList.add("insta-post")
    }

    if (downloadSize === "twitter") {
        setShowInstagramPost(true)
        // el.classList.add("insta-post")
    }


    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }
    setTimeout(() => {
        const elementToCapture = captureDivRef1.current;
        let recall = false;
        // elementToCapture.classList.add('instagram-size-1080');

        if (downloadSize === "twitter") {
            elementToCapture.classList.add('twitter-size-1600');  // New class for Twitter
        }
        elementToCapture.classList.add('instagram-size-1080');
        console.log('Class added for Twitter2:', elementToCapture.classList); // Log the class list

        // Determine the original image source
        const originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage ||
            captureDivRef1.current.querySelector("img#image-div")?.src || '';

        // Handle image and proceed with canvas capture
        if (downloadedImage) {
            const imgElement = elementToCapture.querySelector("img#image-div");
            const divImage = elementToCapture.querySelector("div#image-div");

            if (divImage) {
                divImage.style.backgroundImage = `url("${downloadedImage}")`;
                if (!recall) abcd();
            }

            if (imgElement) {
                imgElement.src = downloadedImage;
                imgElement.onload = () => {
                    if (!recall) abcd();
                };
            }
        } else {
            abcd();
        }

        function abcd() {
            let width, height, owidth, oheight;
            owidth = elementToCapture.offsetWidth;
            oheight = elementToCapture.offsetHeight;
            const paragraphElement = captureDivRef1.current.querySelector('#template-message');
            // console.log(paragraphElement, '<<<<<<<<insta clicked');
            let originalSize = "";
            if (downloadSize === '0') {
                width = elementToCapture.offsetWidth; // Use the current width of the element
                height = elementToCapture.offsetHeight; // Use the current height of the element
            }
            else if (downloadSize === '11') {
                width = 1080;
                height = 1080;
                if (paragraphElement) {
                    let originalSize = paragraphElement.style.fontSize;
                    if (originalSize === "") {
                        originalSize = "20px";
                    }
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                    // console.log(size2,"paragraphElement",paragraphElement,originalSize)

                }
            }
            else if (downloadSize === '2') {
                width = 1080;
                height = 566;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`;
                }
            }
            else if (downloadSize === '3') {
                width = 1080;
                height = 1350;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                }
            }
            // Handle Twitter Post case
            else if (downloadSize === 'twitter') {
                width = 1600;
                height = 900;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize || '10px';
                    const size2 = (parseInt(originalSize) * 1.7);
                    paragraphElement.style.fontSize = `${size2}px`;
                }
            }

            html2canvas(elementToCapture, {
                scale: downloadSize === '0' ? scale : 2,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent",
                onclone: (clonedDoc) => {
                    const clonedElement = clonedDoc.querySelector('.instagram-size-1080');

                    // Apply transformations for Instagram (existing functionality)
                    if (downloadSize === '1' && clonedElement) {
                        clonedElement.classList.add('insta-post');

                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 2}px`;
                        }
                        clonedElement.style.width = '1080px';
                        clonedElement.style.height = '1080px';
                    }

                    else if (downloadSize === 'twitter') {
                        clonedElement.style.width = '1600px';  // Add px for Twitter post width
                        clonedElement.style.height = '900px';  // Add px for Twitter post height
                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 1}px`;
                        }
                    }
                }
            }).then(canvas => {
                handleDownloadSuccess(canvas);
                restoreOriginalState();
                recall = true;
            });
        }

        function handleDownloadSuccess(canvas) {
            const sessionData = JSON.parse(sessionStorage.getItem(window.location.pathname === "/" ? "formData" : "ai_cardData"));
            const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
            const formattedOccasion = (sessionData?.occasion || "").replace(/_/g, '-');


            const filename = `Varnz-${formattedOccasion}-greeting-card-${random4DigitNumber}.png`;

            // Check if HD is stored in sessionStorage
            const isHD = sessionStorage.getItem("isHDInsta") === "true";

            // Set quality based on HD mode
            const quality = isHD ? 1.0 : 0.1;

            // console.log(quality, 'quality');

            const dataURL = canvas.toDataURL('image/webp', quality);
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = filename;

            localStorage.setItem("dataURl", dataURL);
            localStorage.setItem("filename", filename);
            const storedEmail = getItemWithExpiry('user_email');
            const LoginUserEmail = JSON.parse(localStorage.getItem("guitar_user"));

            axios.get(process.env.REACT_APP_VISITED_USERS)
                .then(response => {
                    const userMatch = response.data.data.find(user => user.email === storedEmail);

                    const instagramClicked = localStorage.getItem("instagramClicked");

                    // login user google
                    if (LoginUserEmail) {
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);

                        // Clear the localStorage after download
                        localStorage.removeItem("dataURl");
                        localStorage.removeItem("filename");
                    } else {
                        if (instagramClicked !== "true") {
                            console.log("No data available for download");
                            finalizeDownload(true);
                        }
                    }


                })
                .catch(error => {
                    console.error('Error fetching user records:', error);
                    finalizeDownload(true);
                });
        }

        function restoreOriginalState() {
            if (downloadedImage) {
                const imgElement = captureDivRef1.current.querySelector("img#image-div") || captureDivRef1.current.querySelector("div#image-div");
                if (imgElement) {
                    if (imgElement.tagName === 'IMG') {
                        imgElement.src = originalImage;
                    } else {
                        imgElement.style.backgroundImage = originalImage;
                    }
                }
            }
            elementToCapture.classList.remove('instagram-size-1080');
            setShowInstagramPost(false);
            if (setDownloadCard) {
                setDownloadCard({ download: false, share: false });
            }
        }

        function finalizeDownload(setIsDownloadValue) {
            setDownloadText({ text: "Download", disabled: false });
            setIsDownload(setIsDownloadValue);
        }
    }, 100);
};



export const TwitterHandleDownloadClick = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard, downloadSize, setShowTwitterPost, setIsDownload, setIsSingupDownload, twitter) => {



    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }

    if (downloadSize !== "1" && setDownloadText) {
        setDownloadText({
            text: "Please wait..",
            disabled: true,
        });
    }

    // Handle twitter Post case
    if (downloadSize === "1") {
        // setShowTwitterPost(true);
    }

    if (downloadSize === "twitter") {
        setShowTwitterPost(true)
        // el.classList.add("insta-post")
    }

    if (downloadSize === "twitter") {
        setShowTwitterPost(true)
        // el.classList.add("insta-post")
    }


    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }
    setTimeout(() => {
        const elementToCapture = captureDivRef1.current;
        let recall = false;

        if (downloadSize === "twitter") {
            elementToCapture.classList.add('twitter-size-1600');
        }
        elementToCapture.classList.add('twitter-size-1600');
        console.log('Class added for Twitter3:', elementToCapture.classList); // Log the class list

        // Determine the original image source
        const originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage ||
            captureDivRef1.current.querySelector("img#image-div")?.src || '';

        // Handle image and proceed with canvas capture
        if (downloadedImage) {
            const imgElement = elementToCapture.querySelector("img#image-div");
            const divImage = elementToCapture.querySelector("div#image-div");

            if (divImage) {
                divImage.style.backgroundImage = `url("${downloadedImage}")`;
                if (!recall) abcd();
            }

            if (imgElement) {
                imgElement.src = downloadedImage;
                imgElement.onload = () => {
                    if (!recall) abcd();
                };
            }
        } else {
            abcd();
        }

        function abcd() {
            let width, height, owidth, oheight;
            owidth = elementToCapture.offsetWidth;
            oheight = elementToCapture.offsetHeight;
            const paragraphElement = captureDivRef1.current.querySelector('#template-message');
            console.log(paragraphElement);
            let originalSize = "";
            if (downloadSize === '0') {
                width = elementToCapture.offsetWidth; // Use the current width of the element
                height = elementToCapture.offsetHeight; // Use the current height of the element
            }
            // Handle Twitter Post case
            else if (downloadSize === 'twitter') {
                console.log('tweet 1');

                width = 1060;
                height = 576;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize || '10px';
                    const size2 = (parseInt(originalSize) * 1.7);
                    paragraphElement.style.fontSize = `${size2}px`;
                }
            }

            html2canvas(elementToCapture, {
                scale: downloadSize === '0' ? scale : 1,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent",
                onclone: (clonedDoc) => {
                    const clonedElement = clonedDoc.querySelector('.twitter-size-1600');

                    // Apply transformations for Instagram (existing functionality)
                    if (downloadSize === '1' && clonedElement) {
                        clonedElement.classList.add('twitter-post');

                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 2}px`;
                        }
                        clonedElement.style.width = '1060px';
                        clonedElement.style.height = '576px';
                    }

                    else if (downloadSize === 'twitter') {
                        clonedElement.style.width = '1060px';  // Add px for Twitter post width
                        clonedElement.style.height = '576px';  // Add px for Twitter post height
                        const paragraphElement = clonedElement.querySelector('#template-message');
                        if (paragraphElement) {
                            const originalSize = paragraphElement.style.fontSize || '20px';
                            paragraphElement.style.fontSize = `${parseInt(originalSize) * 1}px`;
                        }
                    }
                }
            }).then(canvas => {
                handleDownloadSuccess(canvas);
                restoreOriginalState();
                recall = true;
            });
        }

        function handleDownloadSuccess(canvas) {
            const sessionData = JSON.parse(sessionStorage.getItem(window.location.pathname === "/" ? "formData" : "ai_cardData"));
            const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
            const formattedOccasion = (sessionData?.occasion || "").replace(/_/g, '-');


            const filename = `Varnz-${formattedOccasion}-greeting-card-${random4DigitNumber}.png`;


            // Check if HD is stored in sessionStorage
            const isHD = sessionStorage.getItem("isHDTwitter") === "true";

            // Set quality based on HD mode
            const quality = isHD ? 1.0 : 0.1;

            // console.log(quality, 'quality');

            const dataURL = canvas.toDataURL('image/webp', quality);
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = filename;

            localStorage.setItem("dataURl", dataURL);
            localStorage.setItem("filename", filename);
            const storedEmail = getItemWithExpiry('user_email');
            const LoginUserEmail = JSON.parse(localStorage.getItem("guitar_user"));


            axios.get(process.env.REACT_APP_VISITED_USERS)
                .then(response => {
                    const userMatch = response.data.data.find(user => user.email === storedEmail);

                    const instagramClicked = localStorage.getItem("twitterClicked");

                    if (LoginUserEmail) {
                        a.click();
                        alert.success("Downloaded Successfully!", { timeout: 3000 });
                        finalizeDownload(false);

                        // Clear the localStorage after download
                        localStorage.removeItem("dataURl");
                        localStorage.removeItem("filename");
                    } else {
                        if (instagramClicked !== "true") {
                            console.log("No data available for download");
                            finalizeDownload(true);
                        }
                    }

                })
        }

        function restoreOriginalState() {
            if (downloadedImage) {
                const imgElement = captureDivRef1.current.querySelector("img#image-div") || captureDivRef1.current.querySelector("div#image-div");
                if (imgElement) {
                    if (imgElement.tagName === 'IMG') {
                        imgElement.src = originalImage;
                    } else {
                        imgElement.style.backgroundImage = originalImage;
                    }
                }
            }
            elementToCapture.classList.remove('twitter-size-1600');
            setShowTwitterPost(false);
            if (setDownloadCard) {
                setDownloadCard({ download: false, share: false });
            }
        }

        function finalizeDownload(setIsDownloadValue) {
            setDownloadText({ text: "Download", disabled: false });
            setIsDownload(setIsDownloadValue);
        }
    }, 100);
};




export const handlePrint = ({ e, captureDivRef1, setPrintPage }) => {
    if (captureDivRef1.current) {
        setPrintPage({
            text: "Please wait..",
            disabled: true
        });
        setTimeout(() => {
            html2canvas(captureDivRef1.current, { useCORS: true, scale: 2 }).then(canvas => {
                const imageData = canvas.toDataURL('image/png');

                // Create a new window to print
                const printWindow = window.open('', '_blank');
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print</title>
                      <style>
                  body, html {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; /* Ensure the image maintains aspect ratio */
                  }
                </style>
                    </head>
                    <body>
                      <img id="printImage" src="${imageData}" />
                    </body>
                  </html>
                `);
                printWindow.document.close();

                // Wait for the image to load before triggering printing
                const printImage = printWindow.document.getElementById('printImage');
                printImage.onload = () => {
                    printWindow.print();
                    setPrintPage({
                        text: "Print",
                        disabled: false
                    });
                };
            });
        }, 100)
    } else {
        console.error('captureDivRef1 is not initialized');
    }
}





export const Result_Overlay = ({ index, downloadText, setDownloadText, captureDivRef1, title, sendData, showMessage, showTabs, handleClick, data, alert, design, clickedTabs, setDownloadCard, isDisabled, bannerThemeResponse, setIsDownload, setIsSingupDownload, setShowTwitterPost, setShowInstagramPost }) => {
    const [sharePlease, setSharePlease] = useState({
        text: "Share",
        disabled: false
    });
    const [printPage, setPrintPage] = useState({
        text: "Print",
        disabled: false
    });
    const [size, setSize] = useState("0");
    const [showSize, setShowSize] = useState(false);
    const [isHovered, setIsHovered] = useState({});
    const handleMouseLeave = (index) => {
        setIsHovered({
            [index]: false
        })
    }
    const handleMouseEnter = (index) => {
        setIsHovered({
            [index]: true
        })
    }

    // console.log(isDisabled,"isDisabled")
    return (
        <>
            <div className="result_overlay_info new-action-btns" id={`social-btn_${index}`}>
                <div className="action-btns-left">
                    <div className="inner_performing_buttons greeting-card-share card-share" onClick={(e) => shareImageCreate(e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard)}>
                        <a href="javascript:void(0);"
                            style={{
                                cursor: sharePlease.disabled ? 'not-allowed' : 'pointer',
                                background: isHovered[`share-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`share-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            className="greeting-card-share"
                            onMouseEnter={(e) => { handleMouseEnter(`share-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`share-${index}`) }}
                        >

                            {
                                sharePlease.disabled ? (
                                    <i id="loader" class="fas fa-spinner loader"></i>

                                ) : (
                                    <img src={share} alt="Image" className="greeting-card-share" />
                                )
                            }
                            <span className="action-btn-tooltip">Share</span>
                        </a>
                    </div>
                    <div className="inner_performing_buttons edit message-edit edit-card" >
                        <Link
                            to={`/greeting-card-design-studio`} onClick={() => { handleClick(data, design, showTabs); localStorage.setItem("hompepage", "edit_page"); }}
                            className="message-edit"
                            target="_blank"
                            style={{
                                background: isHovered[`edit-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`edit-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`edit-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`edit-${index}`) }}
                        >
                            <img src={edit} alt="Image" className="message-edit" />
                            <span className="action-btn-tooltip">Edit</span>
                        </Link>
                    </div>
                    <div className="inner_performing_buttons edit message-edit edit-card " style={{ fontSize: "23px" }} >
                        <Link
                            to={`javascript:void();`} onClick={(e) => { handlePrint({ e, captureDivRef1, setPrintPage }) }}
                            className="message-edit"
                            style={{
                                background: isHovered[`print-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`print-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`print-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`print-${index}`) }}
                        >
                            {
                                printPage.disabled ? (
                                    <i id="loader" class="fas fa-spinner loader"></i>

                                ) : (
                                    <i class="fas fa-print"></i>
                                )
                            }

                            <span className="action-btn-tooltip">Print</span>
                        </Link>
                    </div>
                </div>
                <div className="action-btns-right">

                    <div className="dowbload-dropdown">
                        <div id="download-btn" className="greeting-card-download download-btn" onClick={(e) => {
                            if (isDisabled) {
                                return;
                            }
                            handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, size, setShowInstagramPost, setIsDownload)
                        }
                        }
                            style={{
                                cursor: downloadText.disabled ? 'not-allowed' : isDisabled ? 'not-allowed' : 'pointer',
                                background: isHovered[`dl-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`dl-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`dl-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`dl-${index}`) }}
                        >
                            <a style={{ cursor: downloadText.disabled ? 'not-allowed' : isDisabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" className="">
                                {
                                    downloadText.disabled ? (
                                        <i id="loader" class="fas fa-spinner loader"></i>
                                    ) : (
                                        <img src={download} alt="Image" className="greeting-card-download" />
                                    )
                                }
                            </a>
                            Download
                        </div>
                        <div className="download-arrow " onClick={() => setShowSize(!showSize)}
                            style={{
                                background: isHovered[`dl-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                // border: isHovered[`dl-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`dl-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`dl-${index}`) }}
                        ><i class="fa-solid fa-caret-down"></i></div>
                        <div className={`download-dropdown-option ${showSize && 'active'}`}>
                            <ul>

                                <li
                                    id="insta-post"
                                    onClick={(e) => {
                                        if (isDisabled) {
                                            return;
                                        }
                                        setShowSize(false);
                                        InstahandleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, "1", setShowInstagramPost, setIsDownload, setIsSingupDownload)
                                    }
                                    }>
                                    Instagram (1:1)
                                </li>

                                <li
                                    id="twitter-post"
                                    onClick={(e) => {
                                        if (isDisabled) {
                                            return;
                                        }
                                        setShowSize(false);
                                        TwitterHandleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, "1", setShowTwitterPost, setIsDownload, setIsSingupDownload);
                                    }}
                                >
                                    Twitter Size
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </div>
            {
                // downloadText.disabled
                downloadText.disabled &&
                <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
                    <div class="skype-loader">
                        <div class="dot">
                            <div class="first"></div>
                        </div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            }

        </>
    )
}


export const Result_OverlayOld = ({ index, downloadText, setDownloadText, captureDivRef1, title, sendData, showMessage, showTabs, handleClick, data, alert, design, clickedTabs, setDownloadCard }) => {
    const [sharePlease, setSharePlease] = useState({
        text: "Share",
        disabled: false
    });
    const [size, setSize] = useState("same_size");
    const handleOptionChange = (event) => {
        console.log(event.target.value, "kkkkkkkkkkk")
        // if(event.target.value === "1080"){
        //     setSize({
        //         width: 1080,
        //         height: 1080
        //     })
        // }else{
        setSize(event.target.value);
        // }
    };
    return (
        <div className="result_overlay_info" id={`social-btn_${index}`}>
            <div id="download-btn" className="inner_performing_buttons greeting-card-download download-btn" onClick={(e) => handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, size)
            }>
                <a style={{ cursor: downloadText.disabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" className="greeting-card-download">
                    <img src={download} alt="Image" className="greeting-card-download" />
                    <p className="greeting-card-download">{downloadText.text}</p>
                </a>
            </div>
            <div>
                <select
                    value={size}
                    onChange={handleOptionChange}
                >
                    <option value="">Select...</option>
                    <option value="0">same size</option>
                    <option value="1">1080x1080</option>
                    <option value="2">1080x566</option>
                    <option value="3">1080x1350</option>
                </select>
            </div>
            <div className="inner_performing_buttons greeting-card-share card-share" onClick={(e) => shareImageCreate(e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard)}>
                <a href="javascript:void(0);" style={{ cursor: sharePlease.disabled ? 'not-allowed' : 'pointer' }} className="greeting-card-share" >
                    <img src={share} alt="Image" className="greeting-card-share" />
                    <p className="greeting-card-share">{sharePlease.text}</p>
                </a>
            </div>
            <div className="inner_performing_buttons edit message-edit edit-card" >
                <Link
                    to={`/edit`} onClick={() => { handleClick(data, design, showTabs); localStorage.setItem("hompepage", "edit_page"); }}
                    className="message-edit"
                    target="_blank"
                >
                    <img src={edit} alt="Image" className="message-edit" />
                    <p className="message-edit">Edit</p>
                </Link>
            </div>
        </div>
    )
}


export const handleDownloadClickOld = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage) => {
    if (downloadText.disabled) {
        return;
    }
    // alert(window.screen.width)
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 3;
    } else {
        scale = 5
    }
    setDownloadText({
        text: "Please wait..",
        disabled: true
    })

    setTimeout(() => {
        let elementToCapture = "";
        elementToCapture = captureDivRef1.current;


        let originalImage;
        if (captureDivRef1.current.querySelector("div#image-div")) {
            originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
        }
        if (captureDivRef1.current.querySelector("img#image-div")) {
            originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
        }

        if (downloadedImage) {

            if (captureDivRef1.current.querySelector("div#image-div")) {
                elementToCapture.querySelector("div#image-div").style.backgroundImage = `url("${downloadedImage}")`;
            }
            if (captureDivRef1.current.querySelector("img#image-div")) {
                elementToCapture.querySelector("img#image-div").src = downloadedImage;
            }
        }
        // const originalImage = captureDivRef1.current.querySelector("#image-div")?.style.backgroundImage;
        // if(downloadedImage){
        //     captureDivRef1.current.querySelector("#image-div").style.backgroundImage = `url("${downloadedImage}")`;
        // }
        // console.log(originalImage,"elelel")




        // const originalHeight = elementToCapture.offsetHeight;
        // const originalWidth = elementToCapture.offsetWidth;
        // elementToCapture.style.objectFit = "contain";
        // elementToCapture.style.height = `${originalHeight}px`;
        // elementToCapture.style.width = `${originalWidth}px`;
        // const templateHeading = elementToCapture.querySelector('.template-heading');
        // const templateMessage = elementToCapture.querySelector('.template-message');
        // original style
        // const originalHeadingFontSize = templateHeading.style.fontSize;
        // const oriignalMessageFontSize = templateMessage.style.fontSize;
        // apply style in template after download
        // templateHeading.style.fontSize = "70px";
        // templateMessage.style.fontSize = "50px";
        // Get the actual width and height of the div
        // const width = elementToCapture.offsetWidth;
        // const height = elementToCapture.offsetHeight;
        // const devicePixelRatio = window.devicePixelRatio || 1;

        // Calculate the width and height based on the device's pixel ratio
        // const width = elementToCapture.clientWidth * devicePixelRatio;
        // const height = elementToCapture.clientHeight * devicePixelRatio;

        if (is_dom && 1 === 2) {
            domtoimage.toPng(elementToCapture, { scale: 4, useCORS: true, quality: 1, })
                .then((dataUrl) => {
                    const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                    let filename = "";
                    if (title === "Others")
                        filename = `varnz_message_${random4DigitNumber}.png`;
                    else
                        filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

                    // Create a link element to trigger the download
                    const a = document.createElement('a');
                    a.href = dataUrl;
                    a.download = filename;

                    // Trigger the download
                    document.body.appendChild(a);
                    a.click();

                    // Cleanup: remove the link element from the DOM
                    document.body.removeChild(a);

                    alert.success("Downloaded Successfully!", {
                        timeout: 3000
                    });

                    // Reset the download button state if needed
                    setDownloadText({
                        text: "Download",
                        disabled: false
                    });

                    // Perform any additional actions or data updates
                    sendData("download-successfully");
                })
                .catch((error) => {
                    console.error('Error capturing element:', error);
                });
        } else {
            html2canvas(elementToCapture, { scale: scale, quality: 1, useCORS: true }).then((canvas) => {
                if (downloadedImage) {
                    if (captureDivRef1.current.querySelector("div#image-div")) {
                        captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = `url("${originalImage}")`;
                    }
                    if (captureDivRef1.current.querySelector("img#image-div")) {
                        captureDivRef1.current.querySelector("img#image-div").src = originalImage;
                    }
                }
                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = "";
                if (title === "Others")
                    filename = `varnz_message_${random4DigitNumber}.png`;
                else
                    filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;
                a.click();
                alert.success("Downloaded Successfully!", {
                    timeout: 3000
                });

                setDownloadText({
                    text: "Download",
                    disabled: false
                });
                sendData("download-successfully")
            });
        }







        // domtoimage.toPng(elementToCapture, { width, height, useCORS: true,quality: 1 })
        //     .then((dataUrl) => {
        //         const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
        //         let filename = "";
        //         if (title === "Others")
        //             filename = `varnz_message_${random4DigitNumber}.png`;
        //         else
        //             filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

        //         // Create a link element to trigger the download
        //         const a = document.createElement('a');
        //         a.href = dataUrl;
        //         a.download = filename;

        //         // Trigger the download
        //         document.body.appendChild(a);
        //         a.click();

        //         // Cleanup: remove the link element from the DOM
        //         document.body.removeChild(a);

        //         alert.success("Downloaded Successfully!", {
        //             timeout: 3000
        //         });

        //         // Reset the download button state if needed
        //         setDownloadText({
        //             text: "Download",
        //             disabled: false
        //         });

        //         // Perform any additional actions or data updates
        //         sendData("download-successfully");
        //     })
        //     .catch((error) => {
        //         console.error('Error capturing element:', error);
        //     });
    }, 100)
};



// setTimeout(() => {
//     let elementToCapture = "";
//     elementToCapture = captureDivRef1.current;

//     let originalImage;
//     if(captureDivRef1.current.querySelector("div#image-div")){
//         originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
//     }
//     if(captureDivRef1.current.querySelector("img#image-div")){
//         originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
//     }

//     if(downloadedImage){

//         if(captureDivRef1.current.querySelector("div#image-div")){
//             elementToCapture.querySelector("div#image-div").style.backgroundImage = `url("${downloadedImage}")`;
//         }
//         if(captureDivRef1.current.querySelector("img#image-div")){
//             elementToCapture.querySelector("img#image-div").src = downloadedImage;
//         }
//     }

//     html2canvas(elementToCapture, { scale: scale, quality: 1, useCORS: true }).then((canvas) => {

//         const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
//         let filename = "";
//         if (title === "Others")
//             filename = `varnz_message_${random4DigitNumber}.png`;
//         else
//             filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
//         const dataURL = canvas.toDataURL('image/png');
//         const a = document.createElement('a');
//         a.href = dataURL;
//         a.download = `${filename}`;
//         a.click();
//         alert.success("Downloaded Successfully!", {
//             timeout: 3000
//         });


//         setDownloadText({
//             text: "Download",
//             disabled: false
//         });
//         sendData("download-successfully")
//     },100);
//     setTimeout(()=>{
//         if(downloadedImage){
//             if(captureDivRef1.current.querySelector("div#image-div")){
//                 captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = `url("${originalImage}")`;
//             }
//             if(captureDivRef1.current.querySelector("img#image-div")){
//                 captureDivRef1.current.querySelector("img#image-div").src = originalImage;
//             }
//         }
//     },500)
// }, 100)



export const handleDownloadClickOldNew = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard) => {
    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }
    setDownloadText({
        text: "Please wait..",
        disabled: true
    })

    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }

    // console.log("download check",scale)
    setTimeout(() => {
        let elementToCapture = "";
        let recall = false;
        elementToCapture = captureDivRef1.current;

        let originalImage;
        if (captureDivRef1.current.querySelector("div#image-div")) {
            console.log("test22222", downloadedImage)
            originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
        }
        if (captureDivRef1.current.querySelector("img#image-div")) {
            console.log("test33333333", downloadedImage)

            originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
        }

        if (downloadedImage) {

            if (captureDivRef1.current.querySelector("div#image-div")) {
                const divImage = elementToCapture.querySelector("div#image-div");
                divImage.style.backgroundImage = `url("${downloadedImage}")`;

                // // Execute a function after the image has finished loading
                // divImage.onload = function() {
                //     // Run your function here
                if (!recall) {
                    abcd();
                }
                // };
            }
            if (captureDivRef1.current.querySelector("img#image-div")) {
                const imgElement = elementToCapture.querySelector("img#image-div");
                imgElement.src = downloadedImage;
                // Execute a function after the image has finished loading
                imgElement.onload = function () {
                    // Run your function here
                    if (!recall) {
                        abcd();
                    }
                };

            }
        } else {
            abcd()
        }

        function abcd() {
            console.log("test11111111", downloadedImage, scale)
            html2canvas(elementToCapture, {
                scale: scale,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent"
            }).then((canvas) => {

                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = "";
                if (title === "Others")
                    filename = `varnz_message_${random4DigitNumber}.png`;
                else
                    filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;
                a.click();
                alert.success("Downloaded Successfully!", {
                    timeout: 3000
                });


                setDownloadText({
                    text: "Download",
                    disabled: false
                });
                if (setDownloadCard) {
                    setDownloadCard({
                        download: false,
                        share: false
                    })
                }

                // sendData("download-successfully")
                if (downloadedImage) {
                    if (captureDivRef1.current.querySelector("div#image-div")) {
                        captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = originalImage;
                    }
                    if (captureDivRef1.current.querySelector("img#image-div")) {
                        captureDivRef1.current.querySelector("img#image-div").src = originalImage;
                    }
                }
                recall = true;
            });
        }
    }, 100)

};