import React from "react";
import { NewDesign1 } from "./Main/Components/landingpage/designTemplates/NewDesign1";
import { NewDesign2 } from "./Main/Components/landingpage/designTemplates/NewDesign2";
import { NewDesign3 } from "./Main/Components/landingpage/designTemplates/NewDesign3";
import { NewDesign4 } from "./Main/Components/landingpage/designTemplates/NewDesign4";
import { NewDesign5 } from "./Main/Components/landingpage/designTemplates/NewDesign5";
// import img1 from "../src/staticImgTemplate/1.jpg";
// import bg1 from "../src/staticImgTemplate/bg1.png";

import img1 from "../src/staticImgTemplate/1.jpg";
import bg1 from "../src/staticImgTemplate/bg1.png";

import img2 from "../src/staticImgTemplate/temp2-img.png";
import bg2 from "../src/staticImgTemplate/bg2.png";


import img3 from "../src/staticImgTemplate/1.jpg";
import bg3 from "../src/staticImgTemplate/rvfvfv.png";

import img4 from "../src/staticImgTemplate/4.png";
import bg4 from "../src/staticImgTemplate/bg4.png";


import bg5 from "../src/staticImgTemplate/bg5.png";


const NewTemplate1 = (data, sendData) => {


  const dynamicTemplates = [
    {
      id: 1,
      component: NewDesign1,
      data: {
        message: {
          color: "#fff",
          size: 18,
          fontWeight: "normal",
          textAlign: "center",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          textDecoration: "none",
          opacity: 1,
        },
        div: {
          background: "#fff",
          borderRadius: "10px",
          padding: "20px",
          fontFamily: "Arial",
          color: "#000",
          is_opaque: "yes",
          opacity: 1,
          borderColor: "#ccc",
        },
        image: {
          link: img1,
          backgroundFrame: bg1,
          opacity: 0,
        },
      },
      templateData: JSON.stringify({ message: "It's your special day! Wishing you happiness, love, and all your heart desires. May all your dreams come true! 🎂🎈" }),
    },
    {
      id: 2,
      component: NewDesign2,
      data: {
        message: {
          color: "#fff",
          size: 15,
          fontWeight: "normal",
          textAlign: "left",
          fontFamily: "Salsa",
          fontStyle: "normal",
          textDecoration: "none",
          opacity: 1,
        },
        div: {
          background: "#fff",
          borderRadius: "10px",
          padding: "20px",
          fontFamily: "Arial",
          color: "#000",
          is_opaque: "yes",
          opacity: 1,
          borderColor: "#ccc",
        },
        image: {
          link: img2,
          backgroundFrame: bg2,
          opacity: 0,
        },
      },
      templateData: JSON.stringify({ message: "Wishing you a day filled with love, joy, and lots of cake! 🎉🎂 Have a fantastic birthday celebration! 🥳" }),
    },
    {
      id: 3,
      component: NewDesign3,
      data: {
        message: {
          color: "#fff",
          size: 18,
          fontWeight: "normal",
          textAlign: "center",
          fontFamily: "Overlock-Regular",
          fontStyle: "normal",
          textDecoration: "none",
          opacity: 1,
        },
        div: {
          background: "#fff",
          borderRadius: "10px",
          padding: "20px",
          fontFamily: "Arial",
          color: "#000",
          is_opaque: "yes",
          opacity: 1,
          borderColor: "#ccc",
        },
        image: {
          link: img3,
          backgroundFrame: bg3,
          opacity: 0,
        },
      },
      templateData: JSON.stringify({ message: "Happiest birthday! Hope your day is filled with love, laughter, and all your heart desires. 🎉🎂🎈" }),
    },
    {
      id: 4,
      component: NewDesign4,
      data: {
        message: {
          color: "#500B1E",
          size: 18,
          fontWeight: "normal",
          textAlign: "center",
          fontFamily: "Baumans",
          fontStyle: "normal",
          textDecoration: "none",
          opacity: 1,
        },
        div: {
          background: "#fff",
          borderRadius: "10px",
          padding: "20px",
          fontFamily: "Arial",
          color: "#000",
          is_opaque: "yes",
          opacity: 1,
          borderColor: "#ccc",
        },
        image: {
          link: img4,
          backgroundFrame: bg4,
          opacity: 0,
        },
      },
      templateData: JSON.stringify({ message: "Happiest birthday! Hope your day is filled with love, laughter, and all your heart desires. 🎉🎂🎈" }),
    },
    {
      id: 5,
      component: NewDesign5,
      data: {
        message: {
          color: "#2e2929",
          size: 18,
          fontWeight: "normal",
          textAlign: "center",
          fontFamily: "Baumans",
          fontStyle: "normal",
          textDecoration: "none",
          opacity: 1,
        },
        div: {
          background: "#fff",
          borderRadius: "10px",
          padding: "20px",
          fontFamily: "Arial",
          color: "#000",
          is_opaque: "yes",
          opacity: 1,
          borderColor: "#ccc",
        },
        image: {
          link: img4,
          backgroundFrame: bg5,
          opacity: 0,
        },
      },
      templateData: JSON.stringify({ message: "Happiest birthday! Hope your day is filled with love, laughter, and all your heart desires. 🎉🎂🎈" }),
    },
  ];

  
  return (
    <>
      <h1 className="heading-new-templatesss">New Templates</h1>
      <ul className="new-template-lisiing">
      {dynamicTemplates.map((template) => {
        const Component = template.component; 
        return (
          <li key={template.id} id={template.id}>
            <Component
              data={template.data}
              templateData={template.templateData}
              title={template.title}
              index={template.id - 1}
              handleClick={() => console.log(`Tab clicked for template ${template.id}`)}
              clickedTabs={{ tabs: `Tab${template.id}` }}
              sendData={() => console.log(`Send data for template ${template.id}`)}
              showTabs
              message={`Example message for template ${template.id}`}
              shareImage={false}
              color="#000"
              defaultImage={template.data.image.link}
              overlay
              setDownloadCard={() => console.log(`Download card for template ${template.id}`)}
              inlineHandleClick={(action) =>
                console.log(`Inline action: ${action} for template ${template.id}`)
              }
              pouupContent="message_edit"
              handleMessageChange={() => console.log(`Message changed for template ${template.id}`)}
              bannerThemeResponse={{}}
              setIsDownload={() => console.log(`Download status changed for template ${template.id}`)}
            />
          </li>
        );
      })}
    </ul>




    </>
  );
};

export default NewTemplate1;
