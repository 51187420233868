import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OAuthRedirectHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('authToken', token);
      navigate('/tools');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return <div>Processing OAuth response...</div>;
}

export default OAuthRedirectHandler;