import React, { useEffect } from "react";
import Header from "../../../../landingpage/header";
import logo from "../../../../landingpage/image/logo.png";
import employee from "../../../../landingpage/image/employee.jpg";
import download from "../../../../landingpage/image/download_icon.png";
import save from "../../../../landingpage/image/save.png";
import dots from "../../../../landingpage/image/dots.png";
import template25gift from "../../../../landingpage/image/template28-bottom-right.png";
import template25cake from "../../../../landingpage/image/template28-bottom-center.png";
import template25bottomleft from "../../../../landingpage/image/template28-bottom-left.png";
import template25topleft from "../../../../landingpage/image/template28-top-left.png";
import template25topright from "../../../../landingpage/image/template28-top-right.png";
import template25cap from "../../../../landingpage/image/template28-baloon.png";



import { handleDownloadClick } from "../../../../landingpage/designTemplates/commonFunction";
import { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { savedTemplate, editTemplate, Footer, SocialLink } from "./common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../landingpage/image/edit_icon_white.png";
import branding from "../../../../landingpage/image/refer-frnd.png";


const BirthdayTemplate = ({ promptResponse, resultImage, formData, corporateUserInfo, showTemplateTitle, sendData, designType, category, occasion, prompt, savedData, isSavedTemplate, index, setMasonaryValue, setEditData, dispatch, multiformRequest, showFooterButton, titleColor, responseColor, backgroundImage, onlineImage, isEditPage, isPersoanlInfo, otherColor, titleFontFamily,
    messageFontFamily,
    otherFontFamily,
    fontSize,
    fontStyle,
    textAlignment, allFormData,
    isSavedEdit, logo,
    isDefault,
    company_information,
    setShowSocialMedia,
    showUploadImageActive,
    showCompanyInfoBtn,
    nameDesign="name-d3",
    nameStyle
}) => {

    const title_style = {
        font_family: titleFontFamily,
        font_size: fontSize?.titleFontSize,
        font_style: {
            italic: fontStyle?.titleFontStyle.italic,
            bold: fontStyle?.titleFontStyle.bold,
            underline: fontStyle?.titleFontStyle.underline
        },
        text_alignment: textAlignment?.titleTextAlign.textAlign,
        color: titleColor
    }
    const message_style = {
        font_family: messageFontFamily,
        font_size: fontSize?.messageFontSize,
        font_style: {
            italic: fontStyle?.messageFontStyle.italic,
            bold: fontStyle?.messageFontStyle.bold,
            underline: fontStyle?.messageFontStyle.underline
        },
        text_alignment: textAlignment?.messageTextAlign.textAlign,
        color: responseColor
    }
    const other_style = {
        font_family: otherFontFamily,
        font_size: fontSize?.otherFontSize,
        font_style: {
            italic: fontStyle?.otherFontStyle.italic,
            bold: fontStyle?.otherFontStyle.bold,
            underline: fontStyle?.otherFontStyle.underline
        },
        text_alignment: textAlignment?.otherTextAlign.textAlign,
        color: otherColor
    }
    const nameStyles = {
        fontSize: nameStyle?.fontSize?.nameFontSize,
        fontFamily: nameStyle?.nameFontFamily,
        color: nameStyle?.nameColor,
        fontWeight: nameStyle?.fontStyle?.nameFontStyle?.bold?.fontWeight,
        textDecoration: nameStyle?.fontStyle?.nameFontStyle?.underline?.textDecoration,
        fontStyle: nameStyle?.fontStyle?.nameFontStyle?.italic?.fontStyle,
        textAlign: nameStyle?.textAlignment?.nameTextAlign?.textAlign,
        textTransform: "capitalize",
        backgroundColor: (nameDesign === "name-d2" || nameDesign === "name-d3") ? nameStyle?.backgroundColor : "",
        borderColor: (nameDesign === "name-d4") ? nameStyle?.borderColor : "",
    }
    // const templateData = JSON.parse(promptResponse);
    // console.log(formData, designType, category, occasion, "FOrmdTATAT", designType)
    const Navigator = useNavigate();
    const message = promptResponse;
    let name = "";
    let designation = "";
    if (isSavedTemplate) {
        name = savedData?.name
    } else if (isSavedEdit) {
        name = allFormData.name
    } else {
        name = formData?.name
    }
    if (isSavedTemplate) {
        designation = savedData?.designation
    } else {
        designation = formData?.designation
    }
    const captureDivRef1 = useRef(null);
    const alert = useAlert();
    const [downloadText, setDownloadText] = useState({
        text: "Download",
        disabled: false,
    });
    const [saveImage, setSaveImage] = useState({
        text: "Save",
        disabled: false,
    });
    const [persoanlInfo, setPersonalInfo] = useState(false);
    const [imageHeight, setImageHeight] = useState(true);
    useEffect(() => {
        if (isSavedTemplate) {
            // console.log(savedData?.persoanlInfo)
            setPersonalInfo(savedData?.persoanlInfo);
        }
    }, [])
    useEffect(() => {
        if (company_information === true || company_information === false) {
            setPersonalInfo(company_information);
        }
    }, [company_information])
    // useEffect(() => {
    //     if (company_information === true || company_information === false) {
    //         setShowSocialMedia(persoanlInfo);
    //     }
    // }, [persoanlInfo])
    let background_image = null;
    if (isSavedTemplate) {
        if (savedData?.background_image) {
            background_image = {
                backgroundImage: `url(${savedData?.background_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    } else {
        if (backgroundImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    if (isSavedEdit) {
        if (onlineImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    // const imageUrl = isDefault ? resultImage : allFormData?.profile_pic?.includes("storage")
    //     ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}`
    //     : allFormData?.profile_pic || resultImage && URL.createObjectURL(resultImage) || `${process.env.REACT_APP_URL_LOCAL}/${savedData?.profile_pic}`;

    // useEffect(() => {
    //     if (imageUrl) {
    //         const backgroundImage = new Image();
    //         backgroundImage.src = imageUrl;
    //         backgroundImage.onload = () => {
    //             if (backgroundImage.naturalHeight > backgroundImage.naturalWidth) {
    //                 setImageHeight(true)
    //                 const myDiv = document.getElementById("isSavedTemplate");

    //                 // Get the computed height of the div
    //                 const computedHeight = window.getComputedStyle(myDiv).height;
    //                 const computedWidth = window.getComputedStyle(myDiv).width;
    //                 const numericHeight = parseInt(computedHeight, 10);
    //                 const numericWidth = parseInt(computedWidth, 10);
    //                 console.log("Computed Height:", computedHeight, numericHeight);

    //                 // Calculate aspect ratio
    //                 const aspectRatio = backgroundImage.naturalHeight / backgroundImage.naturalWidth;
    //                 // Calculate height
    //                 const height = numericWidth / aspectRatio;
    //                 if (height < numericHeight) {
    //                     setImageHeight(false)
    //                 }
    //             } else {
    //                 setImageHeight(false)
    //             }
    //         };
    //     }
    // }, [imageUrl]);


    const design = (
        <>
            <div className="business-templates-item template28" ref={captureDivRef1} style={background_image}>
            <img className="templte25-topleft" src={template25topleft} />
            <img className="templte25-topright" src={template25topright} />
                <div className="first-temp-head">
                    <img className="temp-logo" id="qqq" src={`${isDefault ? logo : `${process.env.REACT_APP_URL_LOCAL}/${corporateUserInfo.image}`}`} alt="branding" />
                </div>
                <div className="first-temp-mid">
                    <div className="first-temp-mid-left">
                    <h2 id="template-heading" style={{ color: `${titleColor}`, fontFamily: `${titleFontFamily}`, fontSize: `${fontSize?.titleFontSize}`, fontWeight: `${fontStyle?.titleFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.titleFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.titleFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.titleTextAlign.textAlign}` }}>{showTemplateTitle}</h2>
                   <div className="template7-name">
                    <h4 class={`ribbon temp1-name name-d1 name-dsgn ${nameDesign === "name-d1" && 'active'}`}><span class="ribbon-content" style={nameStyles}>{name}</span></h4>
                    <h4 class={`name-d2 name-dsgn ${nameDesign === "name-d2" && 'active'}`} style={nameStyles}>{name}</h4>
                    <h4 class={`name-d3 name-dsgn ${nameDesign === "name-d3" && 'active'}`} style={nameStyles}>{name}</h4>
                    <h4 class={`name-d4 name-dsgn ${nameDesign === "name-d4" && 'active'}`} style={nameStyles}>{name}</h4>
                    <h4 class={`name-d5 name-dsgn ${nameDesign === "name-d5" && 'active'}`} style={nameStyles}>{name}</h4>
                   </div>
                        <h4 class="desination">{designation}</h4>
                        <p id="template-message" style={{ whiteSpace: 'pre-wrap', color: `${responseColor}`, fontFamily: `${messageFontFamily}`, fontSize: `${fontSize?.messageFontSize}`, fontWeight: `${fontStyle?.messageFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.messageFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.messageFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.messageTextAlign.textAlign}` }}>{promptResponse}</p>
                    </div>
                    <div className="first-temp-mid-right-otr">
                     <img className="template25cap" src={template25cap} />
                        <div className="temp7-inner" id="croped-size-el">
                            {
                                isSavedTemplate ? (
                                    <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} src={`${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic}`} alt="temp3-img" />
                                ) : (
                                    !isSavedEdit ? (
                                        isDefault ? (
                                            <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} src={resultImage} alt="temp3-img" />
                                        ) : (
                                            showUploadImageActive?.cropedImage ? (
                                                <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} src={resultImage} alt="temp3-img" />
                                            ):(
                                                <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} src={resultImage && URL.createObjectURL(resultImage)} alt="temp3-img" />
                                            )
                                            
                                        )
                                    ) : (
                                        showUploadImageActive.uploadedImage ? (
                                            <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} src={resultImage && URL.createObjectURL(resultImage)} alt="temp3-img" />
                                        ) : (
                                            showUploadImageActive?.cropedImage ? (
                                                <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} src={`${resultImage}`} alt="temp3-img" />
                                            ):(
                                                <img className="welcome-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} src={`${allFormData?.profile_pic.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}` : allFormData?.profile_pic}`} alt="temp3-img" />
                                            )
                                            
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="template-elements">
                    <img className="template25-bottomleft" src={template25bottomleft} />
                    <img className="templte25-cake" src={template25cake} />
                    <img className="templte25-gift" src={template25gift} />
                </div>
                <div className="first-temp-footer">
                    {
                        persoanlInfo &&
                        <ul className="brdr-ftr-design">
                            {
                                corporateUserInfo?.website_url && <li style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="link-icon"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.website_url}`}>{corporateUserInfo.website_url}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.facebook_link && <li className="social-temp-link facebook-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-facebook-f"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.facebook_link}`}>{corporateUserInfo.facebook_link}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.instagram_link && <li className="social-temp-link instagram-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-instagram"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.instagram_link}`}>{corporateUserInfo.instagram_link}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.twitter_link && <li className="social-temp-link twitter-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-twitter"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.twitter_link}`}>{corporateUserInfo?.twitter_link}</a>
                                </li>
                            }
                        </ul>
                    }
                </div>
                {
                    !showFooterButton &&
                    <Footer
                        index={index}
                        persoanlInfo={persoanlInfo}
                        setPersonalInfo={setPersonalInfo}
                        setMasonaryValue={setMasonaryValue}
                        downloadText={downloadText}
                        handleDownloadClick={handleDownloadClick}
                        setDownloadText={setDownloadText}
                        captureDivRef1={captureDivRef1}
                        showTemplateTitle={showTemplateTitle}
                        sendData={sendData}
                        alert={alert}
                        savedTemplate={savedTemplate}
                        isSavedTemplate={isSavedTemplate}
                        saveImage={saveImage}
                        formData={formData}
                        designType={designType}
                        category={category}
                        occasion={occasion}
                        promptResponse={promptResponse}
                        corporateUserInfo={corporateUserInfo}
                        prompt={prompt}
                        setSaveImage={setSaveImage}
                        backgroundImage={backgroundImage}
                        onlineImage={onlineImage}
                        title_style={title_style}
                        message_style={message_style}
                        other_style={other_style}
                        isSavedEdit={isSavedEdit}
                        allFormData={allFormData}
                        save={save}
                        isEditPage={isEditPage}
                        editTemplate={editTemplate}
                        Navigator={Navigator}
                        editIcon={editIcon}
                        download={download}
                        setEditData={setEditData}
                        dispatch={dispatch}
                        showCompanyInfoBtn={showCompanyInfoBtn}
                    />
                }
                {persoanlInfo && <div className="template3-footer new-btm-ftr">
                    <div className="social-temp-link address-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                        <i>
                            <svg class="address-icon" fill="fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15px" height="15px" viewBox="0 0 395.71 395.71" xmlSpace="preserve">
                                <g>
                                    <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.951-6.738c5.305-7.211,129.896-177.501,129.896-250.388
                            C335.168,61.609,273.569,0,197.849,0z M197.849,199.571c-27.633,0-50.143-22.51-50.143-50.143s22.51-50.143,50.143-50.143
                            s50.143,22.51,50.143,50.143S225.482,199.571,197.849,199.571z" />
                                </g>
                            </svg>
                        </i>
                        <p style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }}>{corporateUserInfo.company_address}</p>
                    </div>
                </div>
                }
                <p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
            </div>
        </>
    );
    return design
}

export default React.memo(BirthdayTemplate);