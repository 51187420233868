import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from "react";
import { useAlert } from "react-alert";
import { Result_Overlay } from "./commonFunction";
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from "react-masonry-component";
import height from "./commonFunction/height";
const masonryOptions = {
	transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };

const backgroundFrame = "/frame/background/background-frame7.png";

export const FiftheenDesign2 = ({
	data,
	templateData,
	title,
	index,
	handleClick,
	clickedTabs,
	sendData,
	showTabs,
	message,
	shareImage,
	color,
	defaultImage,
	overlay,
	setDownloadCard,
	inlineHandleClick,
	pouupContent,
	handleMessageChange,
	bannerThemeResponse,
	setIsDownload,
}) => {
	const messageStyle = {
		whiteSpace: "pre-wrap",
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	};

	const DynamicOpacity = {
		opacity: data?.message?.opacity ? data?.message?.opacity : "0.2",
	}

	let showMessage = "";
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData;
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false,
	});
	const [showInstagramPost, setShowInstagramPost] = useState(false);

	const [imageLoaded, setImageLoaded] = useState(true);

	const handleImageLoad = () => {
		// setImageLoaded(true);
	};

	
	const [setDynamicHeight, setSetDynamicHeight] = useState(0);

    useEffect(() => {
        const resultItemsDiv = document.querySelector('.result_items');
        if (resultItemsDiv) {
            const height = resultItemsDiv.offsetHeight;
            localStorage.setItem('dynamicHeight', height);
            setSetDynamicHeight(height);
        }
    }, []);

	const design = (
		<>
			<div className="result_items fidtheen-design fidtheen-design2">
				{/* <button onClick={()=>{inlineHandleClick("message")}}>Click Me</button> */}

				{!shareImage && (
					<Result_Overlay
						clickedTabs={clickedTabs}
						index={index}
						downloadText={downloadText}
						setDownloadText={setDownloadText}
						captureDivRef1={captureDivRef1}
						title={title}
						sendData={sendData}
						showMessage={showMessage}
						showTabs={showTabs}
						handleClick={handleClick}
						data={data}
						alert={alert}
						design={"FiftheenDesign2"}
						setDownloadCard={setDownloadCard}
						isDisabled={!imageLoaded}
						setShowInstagramPost={setShowInstagramPost}
						bannerThemeResponse={bannerThemeResponse}
						setIsDownload={setIsDownload}
					/>
				)}
				{
					<div
						ref={captureDivRef1}
						className="inner_result_data design_one"
						style={{
							backgroundColor: `${data?.div?.background}`,
							backgroundSize:
								clickedTabs?.tabs === "NewTemplates"
									? ""
									: `${data.image.backgroundCover && "cover"}`,
						}}
					>
						<div
							className={`editor-page edit-page-bg ${pouupContent === "background_image" && "active"
								}`}
							onClick={() => {
								inlineHandleClick("background_image");
							}}
						>
							<img src={"image/edit-page/eidt-page-bg.png"} />
						</div>
						<div
							className="edit-background-frame"
							style={{
								opacity: `${DynamicOpacity.opacity}`,
								backgroundImage: `url(${data.image?.backgroundFrame?.includes("storage")
										? `${process.env.REACT_APP_URL_LOCAL}/${data.image?.backgroundFrame}`
										: data.image?.backgroundFrame || backgroundFrame
									})`,
							}}
						></div>

						<div
							className="result-inner"
							style={{
								maxWidth: data?.div?.background ? "90%" : "",
								borderRadius:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.borderRadius}`,
								padding:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.padding}`,
								fontFamily:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.fontFamily}`,
								color:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.color}`,
								opacity:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.is_opaque === "yes"
											? data?.div?.opacity
											: "inherit"
										}`,
								borderColor:
									clickedTabs?.tabs === "NewTemplates"
										? ""
										: `${data?.div?.borderColor}`,
							}}
						>
							<p
								style={messageStyle}
								className="template-message"
								id="template-message"
								contentEditable={pouupContent === "message_edit" && true}
								onBlur={handleMessageChange}
							>
								{showMessage.message}
							</p>
							<div
								className={`editor-page edit-page-text ${pouupContent === "message_edit" && "active"
									}`}
								onClick={() => {
									inlineHandleClick("message_edit");
								}}
							>
								<img src={"image/edit-page/eidt-page-text.png"} />
							</div>
						</div>
						<div className="result-template-img">
							<div
								className={`editor-page edit-page-image ${pouupContent === "image_edit" && "active"
									}`}
								onClick={() => {
									inlineHandleClick("image_edit");
								}}
							>
								<img src={"image/edit-page/eidt-page-image.png"} />
							</div>
							<div
								className={`result-template-inner-img ${showInstagramPost && "insta-post"
									}`}
							>
								{overlay && (
									<div
										className="template-overlay"
										style={{
											background: "#000",
											opacity: `${data?.image?.opacity}`,
										}}
									></div>
								)}
								{!imageLoaded && (
									<div
										style={{ width: "100%", height: "100%" }}
										className="please-wait new-please-wait"
									>
										<div class="skype-loader">
											<div class="dot">
												<div class="first"></div>
											</div>
											<div class="dot"></div>
											<div class="dot"></div>
											<div class="dot"></div>
										</div>
									</div>
								)}
								<img
									className="forth-design-new-image"
									id="image-div"
									src={`${data.image.link || defaultImage}`}
									onLoad={handleImageLoad}
								/>
							</div>
						</div>
						<p className="branding-logo ">
							<img src={branding} alt="branding" />
							Created with Varnz.ai
						</p>
					</div>
				}
			</div>
		</>
	);
	return design;
};
