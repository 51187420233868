import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CallbackHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams, 'urlParams');
    
    const code = urlParams.get('code');

    if (code) {
      fetch('/api/auth/google/call-back', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.token) {
            localStorage.setItem('authToken', data.token);
            navigate('/tools'); 
            navigate('/login');
          }
        })
        .catch(() => {
          navigate('/login'); 
        });
    } else {
      navigate('/login'); 
    }
  }, [navigate]);

  return <div>Processing Callback...</div>;
}

export default CallbackHandler;
