import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import sharevector from "../landingpage/image/share-plane.png";
import copy from "../landingpage/image/copy_icon.png";
import sharefirst from "../landingpage/image/share-page-first.png";
import giftbox from "../landingpage/image/gift-box.gif";
import Header from "./header";
import { useAlert } from 'react-alert';
import Footer from "./footer";
import {
	FacebookShareButton,
	TwitterShareButton,
	FacebookIcon,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	EmailIcon,
	EmailShareButton,
} from "react-share";
import branding from "./image/branding.png";
import { imageTemplate, commonTabs, defaultProp } from "./imageTemplate";
import { useCallback } from 'react';
import CarouselComponent from './carosel/Carousel';
import download from "../landingpage/image/download_icon.png";

import { OccasionShare } from '../chat/OccasionShare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ShareImage = () => {
	const location = useLocation();
	const alert = useAlert();
	const imageShareLink = window.location.href;
	const Navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const imageId = queryParams.get('image');
	const [imageResponse, setImageResponse] = useState("");

	console.log(imageResponse, 'imageResponse');

	const addMore = useCallback((newData) => {
		console.log(newData, 'newData');

		return setImageResponse(newData);
	}, [imageResponse]);

	const fetchImage = async () => {
		try {
			const apiUrl = `${process.env.REACT_APP_SHARE_IMAGE_URL}/${imageId}`;
			console.log(apiUrl, 'apiUrl');
			const response = await axios.get(apiUrl);
			if (response.data.status === 0) {
				Navigate("/")
				return;
			}
			addMore(response.data.data)
			document.querySelector('meta[property="og:title"]').setAttribute('content', "TEST");
			document.querySelector('meta[property="og:description"]').setAttribute('content', "TEST1234");
			document.querySelector('meta[property="og:image"]').setAttribute('content', `${process.env.REACT_APP_URL_LOCAL}/${response.data.data?.image_link}`);
			setTimeout(() => {
				document.getElementById("v-pills-messages-tab-0").click()
			}, 100)
		} catch (err) {
			Navigate("/")
			return;
		}
	}
	const handleCopy = (textToCopy) => {
		// Create a temporary textarea element to copy text to clipboard
		const textArea = document.createElement('textarea');
		textArea.value = textToCopy;

		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		alert.success('Link copied!', {
			timeout: 3000
		});
		// sendData("share-text-copied")
		// setAlertShown(true);
		// setTimeout(() => {
		// 	setAlertShown(-1);
		// }, 3000)
		// You can also provide a user feedback message here, e.g., "Text copied to clipboard!"
	};
	const handleDownloadClick = () => {
		let image_link = `${process.env.REACT_APP_URL_LOCAL}/${imageResponse?.image_link}`;
		const img = new Image();
		img.crossOrigin = '*'; // Set cross-origin attribute for external images
		img.src = image_link;
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);

			// Get the data URL of the image from the canvas
			const dataURL = canvas.toDataURL('image/jpeg');
			const link = document.createElement('a');
			link.href = dataURL;
			// link.download = `varnz-${imageResponse.title}.png`; // Set the desired file name
			const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
			let filename = (JSON.parse(sessionStorage.getItem("ai_cardData"))?.occasion || "").replace(/_/g, '-');
			link.download = `Varnz-${filename}-greeting-card-${random4DigitNumber}.png`;
			link.click();
			alert.success("Downloaded Successfully!", {
				timeout: 3000
			});
		}
	};
	useEffect(() => {
		if (!imageId) {
			Navigate("/")
			return;
		}
		fetchImage();
	}, []);
	const [clickedTabs, setClickedTabs] = useState({
		tabs: "",
		subTabs: ""
	});

	const [upcomingEventLists, setUpcomingEventLists] = useState("");
	const [categoryList, setCategoryList] = useState("");

	const getUpcomingEvents = async (limit = 10) => {
		try {
			const params = new URLSearchParams();
			params.append('type', 'all_events');
			const response = await axios({
				method: "get",
				url: process.env.REACT_APP_SELECT_OCCASION,
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': ""
				},
				params
			});
			if (response.data.status === 1) {
				const limitedData = response.data.data.slice(0, limit);
				setUpcomingEventLists(limitedData);
			} else {
				setUpcomingEventLists([]);
			}
		} catch (err) {
			console.log(err.message);
		}
	};
	// header component listCategory
	const list_category = async () => {
		try {
			const params = new URLSearchParams();
			params.append('type', 'megamenu');
			const response = await axios({
				method: "get",
				url: process.env.REACT_APP_AI_PAGE_CATEGORY,
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': ""
				},
				params
			});
			if (response.data.status === 1) {
				getUpcomingEvents();
				setCategoryList(response.data.data)
				// console.log(response.data.data, 'response.data.data');
			} else {

			}
		} catch (err) {

		}
	}

	useEffect(() => {
		list_category();
		// getUpcomingEvents();
	}, []);

	return (
		<>
			<Header
				upcomingEventLists={upcomingEventLists}
				categories={categoryList}
				categoryList={categoryList}
			/>
			<section className="share-page" >
				<div className="cstm-container">
					<div className="main_result_lists-otr">
						<div className="share-page-center">
							<img className="share-image-first" alt="share-image-first" src={sharefirst} />
							<img className="share-image-second" alt="share-image-first" src={giftbox} />
							<div className="share-page-left">
								<div className='share-page-top'>
									{/* <h2 className='cstm-h2'>{imageResponse && imageResponse.occasion}</h2> */}
									<h2 className='cstm-h2'>
										{imageResponse &&
											imageResponse.occasion
												.replace(/_/g, ' ')
												.split(' ')
												.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
												.join(' ')
										}
									</h2>
									<a href="javascript:void(0);" class="blue-btn cstm-btn" onClick={handleDownloadClick}><img src={download} width="14px" height="14px" /> Download</a>
								</div>
								<p>{imageResponse && imageResponse.message}</p>
								<div class="footer_socials">
									<FacebookShareButton
										url={imageShareLink}
										// text={"Answermessage"}
										hashtag="#varnz #AI"
									>
										<FacebookIcon size={45} round={true} />
									</FacebookShareButton>
									<WhatsappShareButton url={`${imageShareLink}`}
									// title={shareUrl1} 
									// title={"Answermessage"} 
									>
										<span>
											<WhatsappIcon
												size={45}
												round={true}
											/>
										</span>
									</WhatsappShareButton>
									<a className="share-copy" href="javascript:void(0);" onClick={() => handleCopy(imageShareLink)}><img className="edit-icon" src={copy}></img>
									</a>
								</div>
								<img className="share-left-img" alt="icon1" src={sharevector} />
							</div>
							<div className="result_items" >
								{/* <div className="inner_result_data design_one"  style={{backgroundImage:`url(${process.env.REACT_APP_URL_LOCAL}/${imageResponse?.image_link})`}} >
								</div> */}
								<img src={`${process.env.REACT_APP_URL_LOCAL}/${imageResponse?.image_link}`} />
							</div>
						</div>

						{/* <div className="share-page-right">
							<div className="main_result_lists" >
								{
									imageResponse && <CarouselComponent imageResponse={imageResponse} clickedTabs={clickedTabs} />
								}
								
							</div>
						</div> */}
					</div>
					<div>
						{
							imageResponse && <CarouselComponent imageResponse={imageResponse} clickedTabs={clickedTabs} />
						}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default ShareImage;	