import {
  MULTIFORM_REQUEST,
  MULTIFORM_SUCCESS,
  MULTIFORM_FAILED,
  UPDATE_NAME,
} from "./multiform.state";

const Model = {
  isLoading: false,
  userNotFound: false,
  incorrectPassword: false,
  isLogged: false,
  isLogout: false,
  data: []
}

const MultiformReducer = (state = Model, action) => {
  switch (action.type) {
    case MULTIFORM_REQUEST: return {
      ...state,
      isLoading: true,
      userNotFound: false,
      incorrectPassword: false,
      isLogged: false,
      isLogout: false,
      data: []
    }

    case MULTIFORM_SUCCESS: return {
      ...state,
      isLoading: true,
      userNotFound: false,
      incorrectPassword: false,
      isLogged: true,
      isLogout: false,
      data: action.payload
    }

    case MULTIFORM_FAILED: return {
      ...state,
      isLoading: true,
      userNotFound: true,
      incorrectPassword: false,
      isLogged: false,
      isLogout: true,
      data: []
    }

    case UPDATE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          formData: {
            ...state.data.formData,
            name: action.payload // Update the name with the new value
          }
        }
      }

    default: return {
      ...state,
      isLoading: true,
      userNotFound: action.type,
      incorrectPassword: false,
      isLogged: false,
      isLogout: true,
      data: action.payload
    }
  }
}

export default MultiformReducer;
