import React, { useEffect } from 'react'
import branding from "../multiStepForm/image/branding.png";
import LogoutButton from '../signup/Logout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AvatarWithLetter from '../../../commonComponents/avtar';
import { useState } from 'react';
import "./css/imagination.css"
import { SimplePaymentModal } from './PaymentModal/PaymentModal';

export default function Imaginationheader({ corporateUser, setAlertMessage, selectedTool }) {
  const userData = JSON.parse(localStorage.getItem("guitar_user"));

  const uid = userData.uid;
  const token = localStorage.getItem("Exapirtoken")

  const userEmail = JSON.parse(localStorage.getItem("guitar_user"));

  const [activeAI, setActiveAI] = useState(false);
  const [error, setError] = useState("");

  const [show1, setShow1] = useState(false);
  const handleCloseHandel = () => setShow1(false);

  const Navigator = useNavigate();
  const handleClick = (data) => {
    if (corporateUser >= 0) {
      setAlertMessage("")
      if (corporateUser > 0) {

        Navigator(`/${data}`);
      } else {
        setTimeout(() => {
          setAlertMessage("In order to begin crafting greetings for your team members, kindly provide the necessary business information.")
        }, 100)
      }
    } else {
      Navigator(`/${data}`);
    }
  }
  const navigate = useNavigate();
  const NavigateHistory = () => {
    navigate('/history', { state: { userDetails } });
  }
  const BNavigateHistory = () => {
    navigate('/template-history', { state: { userDetails } });
  }

  const PaymentHistory = () => {
    navigate('/ai-payment-history', { state: { userDetails } });
  }
  const LimitHistory = () => {
    navigate('/ai-limit-history', { state: { userDetails } });
  }
  const Edit = () => {
    navigate('/ai-edit', { state: { userDetails } });
  }

  const BEdit = () => {
    navigate('/settings', { state: { userDetails } });
  }

  const NavigateTool = () => {
    navigate('/tools');
  }

  const NavigateToolBusiness = () => {
    navigate('/multiform');
  }

  const [userDetails, setUserDetails] = useState("");
  // console.log(userDetails, 'userDetails');
  const [loading, setLoading] = useState(false);

  const getUserData = async () => {
    if (loading) return; // Prevent API call if already in progress

    setLoading(true); // Set loading flag to true

    const HEADER_URL = process.env.REACT_APP_TOOL_HEADER;
    try {
      const response = await fetch(`${HEADER_URL}/${uid}`, {
        method: 'GET',
        headers: {
          'x-auth-token': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();

      // Update only if data has changed
      if (data.status === 1 && JSON.stringify(data.data) !== JSON.stringify(userDetails)) {
        setUserDetails(data.data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Reset loading flag after request completes
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getUserData();
  //   }, 100); // Poll every 5 seconds

  //   return () => clearInterval(intervalId); // Cleanup on unmount
  // }, [userDetails, loading]);

  useEffect(() => {
    getUserData();
  }, [userDetails, loading]); 
  
  

  // const getUserData = async () => {
  //   const HEADER_URL = process.env.REACT_APP_TOOL_HEADER
  //   try {
  //     const response = await fetch(`${HEADER_URL}/${uid}`, {
  //       method: 'GET',
  //       headers: {
  //         'x-auth-token': token
  //       }
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch user data');
  //     }

  //     const data = await response.json();
  //     // console.log(data.data, 'user data');

  //     if (data.status === 1) {
  //       setUserDetails(data.data);
  //     } else {
  //       setError(data.message);
  //     }
  //   } catch (error) {
  //     setError
  //       (error.message);
  //   }
  // };

  // useEffect(() => {
  //   getUserData();
  // }, [userData]);

  // console.log(imagePath);

  const URL_LOCAL = process.env.REACT_APP_URL_LOCAL;


  const getImageSrc = (imagePath) => {
    // Return null if imagePath is falsy
    if (!imagePath) return null;

    // Check if imagePath is a full URL (e.g., Google image)
    if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
      return imagePath;
    }

    // Otherwise, prepend URL_LOCAL for local database images
    return `${URL_LOCAL}/${imagePath}`;
  };

  const imagePath = userDetails?.image_path;


  const location = useLocation();


  return (
    <>
      <div className='business-header imagination-new-header'>
        <div className='cstm-container'>
          <div className='busnes-header-inner'>
            <a href="/" className="business-logo"><img src={branding} alt="varnz logo" /></a>
            {/* <div className="middle_side_logo new_itr">
                <div>
                  <div class="tool-header-btns">
                    <a href="/settings" class="blue-btn cstm-btn"><i class="far fa-repeat-alt"></i>Switch to Business</a> 
                  </div>
                </div>
              </div> */}
            <div className="new-header-btns">
              {/* <a href="/tools" className="brder-btn upgrade-btn go-to-tool-btn"><i class="fas fa-filter"></i>Explore Tools</a> */}
              <div className='imgnation-new-header'>
                <a
                  className={`nav-item-header ${location.pathname === "/tools" ? "active" : ""}`}
                  href="/tools"
                >
                  Create Personal Cards
                </a>
                <a
                  className={`nav-item-header ${location.pathname === "/multiform" ? "active" : ""}`}
                  href="/multiform"
                >
                  Create Business Cards
                </a>
              </div>
              <div className="upgrade-otr-btn">
                <a
                  href="#"
                  className="brder-btn upgrade-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow1(true);
                  }}
                ><i class="fas fa-crown"></i>Upgrade</a>
                <p
                  href="#"
                  className=" credits-btn" onClick={(e) => { e.preventDefault(); setShow1(true);}}><i class="fas fa-coins"></i>{userDetails ? userDetails.ai_limit : '0'} Credits</p>
              </div>
              <div className='business-header-user'>
                <div className='business-acount'
                  onClick={() => { setActiveAI(!activeAI) }}
                ><i class="fa-regular fa-user"></i>My Account<i class="far fa-angle-down"></i></div>
                <div className={`business-acount-dropdown ${activeAI && 'active-ai-page'}`}>
                  <div className='user-login-info'>
                    {userDetails ? (
                      imagePath ? (
                        // If imagePath exists, display the image
                        <img src={getImageSrc(imagePath)} alt="User Profile" />
                      ) : (
                        // If imagePath doesn't exist, fallback to AvatarWithLetter
                        <AvatarWithLetter name={userDetails?.name} />
                      )
                    ) : (
                      // If userDetails is null/undefined, fallback to AvatarWithLetter
                      <AvatarWithLetter name={userDetails?.name} />
                    )}

                    <div className='user-login-info-right'>
                      <h4>{userDetails ? userDetails.name : ""}</h4>
                      <p>{userDetails ? userDetails.email : ""}</p>
                    </div>
                  </div>
                  <ul className='user-dropdown'>
                    <p className=" credits-btn mobile-credit mob-non" onClick={NavigateTool}><i class="fas fa-filter"></i> Explore Tools</p>
                    <p href="#" className=" credits-btn mobile-credit mob-non"><i class="fas fa-coins"></i>{userDetails.ai_limit} Credits</p>
                    <h3 class="submenu-heading mob-non"><span>Ai Cards</span></h3>
                    <p className=" credits-btn mobile-credit mob-non" onClick={NavigateTool}><i class="fa-regular fa-envelope-open"></i> Personal Cards</p>
                    <p className=" credits-btn mobile-credit mob-non" onClick={NavigateToolBusiness}><i class="fa-regular fa-envelope-open"></i> Business Cards</p>
                    <h3 class="submenu-heading"><span>Edit</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={Edit}><i class="far fa-user-edit"></i> Edit Personal Information</p>
                    <p className=" credits-btn mobile-credit" onClick={BEdit}><i class="far fa-user-edit"></i> Edit Business Information</p>
                    <h3 class="submenu-heading"><span>Payment History</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={PaymentHistory}><i class="far fa-money-bill-alt"></i> Payment History</p>
                    <p className=" credits-btn mobile-credit" onClick={LimitHistory}><i class="far fa-list-alt"></i> Limit History</p>
                    <h3 class="submenu-heading"><span>Cards History</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={NavigateHistory}><i class="fas fa-history"></i> AI image History</p>
                    <p className=" credits-btn mobile-credit" onClick={BNavigateHistory}><i class="fas fa-history"></i> Business Saved Card History</p>
                    <li><LogoutButton /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SimplePaymentModal
        show1={show1}
        handleCloseHandel={handleCloseHandel}
        toolId={selectedTool}
      />
    </>
  )
}
